import React, { useEffect, useRef } from 'react';
import Layout from '../components/layout';
import WebinarRegistrationPopup from '../components/Popup/webinar-registration-popup';
import SEO from "../components/seo";
import InternationaPatentImg from '../images/internation-patent.webp';
import IPProtectionImg from '../images/aws-openai-soc.jpg';
import '../styles/webinar-page.css';

const WebinarPatentPowerPlay = () => {
    const WEBINAR_TITLE = 'Patent Power Play: Turn Your Product Features into Business Assets';
    const seo = {
        metaDesc: WEBINAR_TITLE
    }
    const WEBINAR_AI_MEETS = "https://webinarkit.com/webinar/registration/oneclick/67c819d04c252cf908877190?date=jit_15";
    const childRef = useRef();
    const openPopup = () => {
        //
        childRef.current?.open(WEBINAR_AI_MEETS);
    }
    //
    useEffect(() => {
        document?.body.classList.add('no-home');
        //
    }, []);

    return (
        <Layout activeMenu={'webinar'}>
            <SEO title={`Webinar ${WEBINAR_TITLE} - Inventiv.org`} canonical='/webinar-patent-power-play-turn-your-product-features-into-business-assets' seo={seo} />

            <div class="sub-banner" style={{ height: 'auto', backgroundColor: '#0C6873' }}>
                <div class="container py-5">
                    <div class="text-center">
                        <h1 className='text-white'> Protect Your Software Innovations </h1>
                        <h6 className='text-white'>The webinar is provided free of cost and is a collaboration between Inventiv and PatentPC</h6>
                    </div>
                </div>
            </div>

            {/* <!-- Rsvp start --> */}
            <div class="rsvp content-area-2" id="rsvp">
                <div class="container px-5">
                    <div className='px-5'>
                        <div class="row">
                            <div class="col-12 wow fadeInUp delay-04s">
                                <div class="row">
                                    <h2 className="text-center bold">{WEBINAR_TITLE}</h2>
                                    <h5 className='text-center'>Created By Patent Lawyers, For Patent Lawyers. </h5>
                                </div>
                            </div>
                        </div>
                        <div className='my-5'>
                            <div className='row'>
                                <div className='col-md-12 row-flex wow fadeInUp delay-04s'>
                                    <div className='div-content'>
                                        <div className='circular_image'>
                                            <img style={{ display: 'block', objectFit: 'cover' }} src={InternationaPatentImg} alt={WEBINAR_TITLE}></img>
                                        </div>
                                        <p className='text-center'>Don’t miss this webinar by our partner PatentPC</p>
                                        <h5 className='bold mt-2 text-center'>Instant watch session available. Join now!</h5>

                                    </div>
                                    <div className='div-content'>
                                        <p>Patents aren’t just legal documents. They’re business weapons. Every feature in your product, if patented correctly, can turn into a powerful asset—one that increases your company’s value, attracts investors, and keeps competitors at bay. Most entrepreneurs see patents as an afterthought, something to consider once they scale. That’s a mistake. The best companies use patents as part of their strategy from day one. Here’s how you can do the same.</p>


                                        <h2>Why Patents Are More Than Just Protection</h2>
                                        <p>When people think of patents, they often focus on protection—keeping competitors from copying an idea. But patents do much more. They create value, open new revenue streams, and give you leverage in negotiations.</p>
                                        <p>For example, Tesla, Apple, and Google don’t just patent innovations to block competitors. They use patents to:</p>



                                        <p className='text-center'>
                                            <a href="#" onClick={e => { e.preventDefault(); openPopup() }} class="btn btn-default my-3" style={{ width: '100%' }}>Watch Webinar Now</a>
                                        </p>

                                    </div>
                                </div>
                            </div>
                            <div className='row'>
                                <div className='col-md-12 wow fadeInUp delay-04s'>

                                    <div className='div-content mw-350'>
                                        <p className='ps-5'>
                                            <ul style={{ listStyle: 'disc' }}>
                                                <li><strong>Increase valuation</strong> – Investors and buyers see patents as proof of innovation and market control.</li>
                                                <li><strong>Create licensing revenue</strong> – Companies can license patents to others, creating an entirely new revenue stream.</li>
                                                <li><strong>Build strategic partnerships</strong> – A strong patent portfolio attracts partners looking for exclusive rights or joint ventures.</li>

                                            </ul>
                                        </p>
                                        <p>If you think patents are just for “big companies,” think again. Smart startups patent their product features early to build a defensible business that grows faster.</p>

                                    </div>

                                    <div className='div-content mw-350'>
                                        <h2>Step 1: Identify Patent-Worthy Features in Your Product</h2>
                                        <p>Not every product feature deserves a patent. You need to focus on features that are:</p>

                                        <p className='ps-5'>
                                            <ul style={{ listStyle: 'disc' }}>
                                                <li><strong>Unique:</strong> Something that competitors don’t already have or can’t easily replicate.</li>
                                                <li><strong>Useful:</strong> The feature should offer a clear advantage, solving a problem in a way others haven’t.</li>
                                                <li><strong>Non-obvious:</strong> It shouldn’t be an obvious tweak to an existing solution.</li>
                                            </ul>
                                        </p>

                                        <p>Look at your product’s core differentiators. What makes it stand out? What’s the "wow" factor that customers love? That’s what you should consider patenting.</p>
                                        <h3>Real-World Example</h3>
                                        <p>Let’s say you run a fitness-tech startup. If your product simply tracks steps, it’s hard to patent. But if your app predicts muscle fatigue based on heart rate and past activity, that’s unique and patent-worthy.</p>


                                    </div>
                                    <div className='div-content mw-350'>
                                        <h2>Step 2: Drafting a Strong Patent Application</h2>
                                        <p>A strong patent application isn’t just about describing the product. It needs to be strategic. A good patent does three things:</p>
                                        < p className='ps-5'>
                                            <ul style={{ listStyle: 'decimal' }}>
                                                <li><strong>Covers Multiple Variations</strong>:
                                                    <p>Don’t just describe the exact feature—describe variations that competitors might try to use as workarounds. If you’ve developed an AI-powered voice assistant, don’t patent just the voice commands—cover different ways the AI processes and responds.</p>
                                                </li>
                                                <li><strong>Focuses on "How" Not Just "What"</strong>:
                                                    <p>Your application should explain how your feature works, not just what it does. Many patents get rejected because they only describe a concept without explaining the underlying method.</p></li>
                                                <li><strong>Uses Broad Yet Specific Language</strong>: <p>There’s a balance between being too broad (which makes your patent weak) and too specific (which makes it easy to bypass). The right wording makes it harder for competitors to work around your patent while still keeping it enforceable.</p></li>
                                            </ul>
                                        </p>

                                    </div>
                                    <div className='div-content mw-350'>
                                        <h2>Step 3: Using Patents as a Business Growth Tool</h2>
                                        <p>Once you have patents in place, it’s time to leverage them for growth.</p>

                                        <h3>1. Attract Investors & Increase Valuation</h3>
                                        <p>Investors love patents because they reduce risk. A patented product is harder for competitors to copy, making the startup more defensible. Companies with strong patent portfolios often raise more money at higher valuations.</p>

                                        <h3>2. Monetize Through Licensing</h3>
                                        <p>Patents don’t just protect your business—they can generate revenue. Licensing allows you to sell rights to your patents while keeping ownership. Many tech companies make billions through licensing deals alone.</p>

                                        <h3>3. Use Patents for Competitive Positioning</h3>
                                        <p>A patented product isn’t just a feature—it’s a market differentiator. If your company owns the patent to a groundbreaking AI feature, you can use it in marketing, partnerships, and sales to position yourself as an industry leader.</p>
                                    </div>

                                    <div className='div-content mw-350'>
                                        <p>Even the best ideas can fail to become assets if not handled correctly. Here are some common patenting mistakes and how to avoid them:</p>
                                        <p className='ps-5'>
                                            <ul style={{ listStyle: 'disc' }}>
                                                <li><strong>Waiting too long:</strong> The earlier you file a patent, the better.</li>
                                                <li><strong>Filing a weak patent:</strong> A vague or overly broad patent can get rejected or challenged.</li>
                                                <li><strong>Ignoring international protection:</strong> If your market extends beyond one country, consider filing internationally.</li>
                                            </ul>
                                        </p>
                                    </div>

                                    <div className='div-content mw-350'>
                                        <h3>Final Thoughts: Make Your Product Features Work for You</h3>
                                        <p>Your product’s best features shouldn’t just be selling points—they should be business assets. Patents turn unique features into long-term advantages, giving your business leverage, protection, and financial opportunities.</p>

                                        <p>Instead of just launching a great product, make sure you’re building a defensible business. Start treating your patents as investments, not expenses. The best businesses don’t just create—they own their innovations.</p>



                                    </div>

                                </div>
                            </div>
                            <div className='row'>
                                <div className='col-md-6 offset-md-3'>
                                    <p className='text-center'>
                                        <a href="#" onClick={e => { e.preventDefault(); openPopup() }} class="btn btn-default my-3" style={{ width: '100%' }}>Watch Webinar Now</a>
                                    </p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div className='container px-5'>
                <div class="d-flex justify-content-center">
                    <img src={IPProtectionImg} alt='Our lawyers have worked on IP protection for' />
                </div>
            </div>
            <WebinarRegistrationPopup ref={childRef}></WebinarRegistrationPopup>
            {/* <!-- Rsvp end --> */}
        </Layout>
    )
};

export default WebinarPatentPowerPlay;